import React from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'

const ListPage = ({data}) => {
  const entry = data.markdownRemark
  const allEntries = data.allMarkdownRemark
	return (
    <div>
      <Nav />
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <div className="w-full pt-12 pb-0">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="flex justify-around items-center text-center relative">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="font-verdana font-bold text-6xl sm:text-6xl lg:text-5xl mb-6 leading-tight">
                <h2>{entry.frontmatter.heading}</h2>
              </div>
              <div className="max-w-none font-verdana prose prose-md lg:prose-lg">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pt-12 pb-12">
        <div className="w-10/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-12">
            {allEntries.edges.map( (entry, i) => {
            const childImage = getImage(entry.node.frontmatter.image)
              return (
                <div key={`child_${i}`} className="group font-verdana text-md lg:text-lg">
                <Link to={entry.node.fields.slug}>
                  <figure className="mb-3">
                    <GatsbyImage image={childImage} alt={entry.node.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                  </figure>
                  <h3 className="border-b-2 border-white group-hover:border-black inline-block">{entry.node.frontmatter.heading}</h3>
                </Link>
              </div> 
            )})}
          </div>
        </div>
      </div>
      <div className="w-full py-12 relative">
        <div className="absolute -inset-0">
          <img src="/images/uploads/shutterstock_1083903761.jpg" alt="" className="object-cover object-top w-full h-full block" /> 
        </div>
        <div className="w-10/12 mx-auto max-w-screen-2xl relative">
          <div className="flex justify-around items-center text-center">
            <div className="w-10/12 sm:w-8/12 lg:w-8/12 xl:w-8/12">
              <div className="max-w-none font-verdana prose prose-md lg:prose-2xl text-center">
                <p>{entry.frontmatter.quote} <strong>&mdash; {entry.frontmatter.author}</strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default ListPage;

export const query = graphql`
	query($slug: String!, $category: String!,) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        quote
        author
			}
			fields {
				slug
			}
      html
		}
    allMarkdownRemark(filter: {frontmatter: {section: {eq: $category}}}) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            heading
            image {
              childImageSharp {
                gatsbyImageData(width: 640)
              }
            }
          }
        }
      }
    }
	}
`